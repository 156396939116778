/**
 * @generated SignedSource<<33352799200cc7a68f05f93797467f28>>
 * @relayHash 69dfebfa3e96ec9980ca10b72e4d3c41
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/488.0.0-2024-11-12T19:25:11.001Z/FavoritesProviderQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesProviderQuery$variables = {
  loadPortfolioData?: boolean | null;
  selectedItemIds?: ReadonlyArray<string | null> | null;
  shouldFillInFolder?: boolean | null;
  shouldShowHeartCount?: boolean | null;
  userIds?: ReadonlyArray<string | null> | null;
};
export type FavoritesProviderQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FolderWrapper_viewer" | "HeartWrapper_viewer">;
  };
};
export type FavoritesProviderQuery = {
  response: FavoritesProviderQuery$data;
  variables: FavoritesProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "loadPortfolioData"
},
v1 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "selectedItemIds"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "shouldFillInFolder"
},
v3 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "shouldShowHeartCount"
},
v4 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "userIds"
},
v5 = {
  "kind": "Variable",
  "name": "selectedItemIds",
  "variableName": "selectedItemIds"
},
v6 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "kind": "Variable",
  "name": "itemIds",
  "variableName": "selectedItemIds"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Item",
  "kind": "LinkedField",
  "name": "item",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "kind": "Literal",
  "name": "portfolioTypes",
  "value": [
    "HEART"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoritesProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "shouldFillInFolder",
                "variableName": "shouldFillInFolder"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FolderWrapper_viewer"
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPortfolioData",
                "variableName": "loadPortfolioData"
              },
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "showHeartCount",
                "variableName": "shouldShowHeartCount"
              },
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "HeartWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "FavoritesProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "condition": "shouldFillInFolder",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "folderItemMatches",
                "args": [
                  (v8/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "FOLDER"
                    ]
                  },
                  (v6/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "loadPortfolioData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "heartItemMatches",
                "args": [
                  (v8/*: any*/),
                  (v13/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioItemId",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  (v13/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "shouldShowHeartCount",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v8/*: any*/)
                ],
                "concreteType": null,
                "kind": "LinkedField",
                "name": "itemRead",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioItemDetailsType",
                        "kind": "LinkedField",
                        "name": "portfolioItemDetails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "likes",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Item",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/488.0.0-2024-11-12T19:25:11.001Z/FavoritesProviderQuery",
    "metadata": {},
    "name": "FavoritesProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "cf0d07b40715691441fb9b16188728dc";

export default node;

import { Component } from 'react';
import { fetchQuery_DEPRECATED as fetchQuery } from 'react-relay/legacy';
import { createOperationDescriptor, getRequest } from 'relay-runtime';
import propTypes from 'prop-types';

class SharedParallelizedQueryRenderer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = { data: {} };

        this.fetchQueryInProgress = false;
        this.queuedFetchQueryVariables = null;
        this._selectionReference = null;
    }

    /**
     * NOTE: `componentDidUpdate` checks that the "instance" of the variables object in props passed has changed to
     * determine whether to fetch more data. Even if the values of the keys of the variables object are the same, if
     * the variables object is a different reference this will trigger a fetch.
     */
    componentDidUpdate(prevProps) {
        // Is there a fetchQuery that has not yet completed?
        if (this.fetchQueryInProgress) {
            // If previous fetchQuery has not resolved but we detect change in variables, stash variables
            if (prevProps.variables !== this.props.variables) {
                this.queuedFetchQueryVariables = this.props.variables;
            }
        }
        // If there is a change in variables and there is no fetchQuery in progress, get moar data!
        else if (prevProps.variables !== this.props.variables) {
            this.getData();
        }
        // Are there queued variables from a previous update?
        // If queued variables are the same as those in props, get data
        else if (
            this.queuedFetchQueryVariables &&
            this.queuedFetchQueryVariables === this.props.variables
        ) {
            this.queuedFetchQueryVariables = null;
            this.getData();
        }
        // If queued variables are not same as those in props, assume queued variables are stale, get data not needed
        else if (
            this.queuedFetchQueryVariables &&
            this.queuedFetchQueryVariables !== this.props.variables
        ) {
            this.queuedFetchQueryVariables = null;
        }
    }

    getData() {
        this.fetchQueryInProgress = true;
        const operation = createOperationDescriptor(
            getRequest(this.props.query),
            this.props.variables
        );

        fetchQuery(this.props.environment, this.props.query, this.props.variables)
            .then(data => {
                this.fetchQueryInProgress = false;
                this.setState({ data });
                if (this._selectionReference) {
                    this._selectionReference.dispose();
                }
                this._selectionReference = this.props.environment.retain(operation);
            })
            .catch(() => {
                this.fetchQueryInProgress = false;
            });
    }

    render() {
        const { data } = this.state;
        return this.props.render({ data });
    }
}

SharedParallelizedQueryRenderer.propTypes = {
    query: propTypes.object.isRequired,
    variables: propTypes.object.isRequired,
    environment: propTypes.object.isRequired,
    render: propTypes.func.isRequired,
};

export { SharedParallelizedQueryRenderer };

import {
    trackEvent,
    eventNameConstants,
    interactionTypeConstants,
    stepInteractionConstants,
    StepInteractionNameValues,
} from 'dibs-tracking';
import { hasKey } from 'dibs-ts-utils/exports/hasKey';

const { EVENT_WISHLIST } = eventNameConstants;
const { INTERACTION_TYPE_ITEM_FAVORITING } = interactionTypeConstants;
const {
    STEP_INTERACTION_ITEM_ADDED,
    STEP_INTERACTION_ITEM_REMOVED,
    STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
    STEP_INTERACTION_ITEM_FOLDER_CLICKED,
    STEP_INTERACTION_ITEM_FOLDER_CREATED,
    STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER,
} = stepInteractionConstants;

const SIDEBAR = 'SIDEBAR';
const SELLER_GRID = 'SELLER_GRID';
const SIMILAR_ITEM_GRID = 'SIMILAR_ITEM_GRID';
const SIMILAR_ITEM_TOP = 'SIMILAR_ITEM_TOP';
const PERSONALIZED_ITEM_LIST = 'PERSONALIZED_ITEM_LIST';
const COLLECTION_ITEM_LIST = 'COLLECTION_ITEM_LIST';
const VISUALLY_SIMILAR_ITEM_GRID = 'VISUALLY_SIMILAR_ITEM_GRID';
const VISUALLY_SIMILAR_ITEM_LIST = 'VISUALLY_SIMILAR_ITEM_LIST';
const SEARCH_BROWSE = 'SEARCH_BROWSE';
const ZOOM_MODAL = 'ZOOM_MODAL';
const MOBILE_NAV = 'MOBILE_NAV';
const AUTO_EMAIL = 'AUTO_EMAIL';
const SIMILAR_SOLD = 'SIMILAR_SOLD';
const NONE = 'NONE';

export const locations = {
    SIDEBAR,
    SELLER_GRID,
    SIMILAR_ITEM_GRID,
    SIMILAR_ITEM_TOP,
    PERSONALIZED_ITEM_LIST,
    COLLECTION_ITEM_LIST,
    VISUALLY_SIMILAR_ITEM_GRID,
    VISUALLY_SIMILAR_ITEM_LIST,
    SEARCH_BROWSE,
    ZOOM_MODAL,
    MOBILE_NAV,
    AUTO_EMAIL,
    NONE,
    SIMILAR_SOLD,
} as const;

export const authLabels = {
    [locations.SIDEBAR]: 'save item - product page',
    [locations.SELLER_GRID]: 'save item - product page - more from dealer',
    [locations.SIMILAR_ITEM_GRID]: 'save item - product page - you may also like',
    [locations.SIMILAR_ITEM_TOP]: 'save item - product page - top you may also like',
    [locations.SEARCH_BROWSE]: 'save item - search/browse',
    [locations.MOBILE_NAV]: 'save item - product nav',
} as const;

export const heartLabels: Record<(typeof locations)[keyof typeof locations], string> = {
    [locations.SIDEBAR]: 'PDP',
    [locations.SELLER_GRID]: 'PDP - more from dealer',
    [locations.SIMILAR_ITEM_GRID]: 'PDP - you may also like',
    [locations.SIMILAR_ITEM_TOP]: 'PDP - top you may also like',
    [locations.PERSONALIZED_ITEM_LIST]: 'PDP - personalized items carousel',
    [locations.COLLECTION_ITEM_LIST]: 'PDP - collection items carousel',
    [locations.MOBILE_NAV]: 'PDP - mobile navigation',
    [locations.VISUALLY_SIMILAR_ITEM_GRID]: 'PDP - visually similar item',
    [locations.VISUALLY_SIMILAR_ITEM_LIST]: 'PDP - visually similar items carousel',
    [locations.SEARCH_BROWSE]: 'search/browse',
    [locations.AUTO_EMAIL]: 'PDP - auto email',
    [locations.SIMILAR_SOLD]: 'similar sold item',
    [locations.NONE]: '',
    [locations.ZOOM_MODAL]: '',
};
export type FavoritesLocation = keyof typeof heartLabels;

const folderLabels = {
    [locations.SEARCH_BROWSE]: 'search/browse',
    [locations.SIMILAR_SOLD]: 'similar sold item',
} as const;

function trackHeartEvent(
    location: FavoritesLocation,
    action: string | undefined,
    itemId: string | null | undefined,
    step_interaction_name: StepInteractionNameValues,
    isSponsoredItem: boolean | null = null,
    isNewListing: boolean = false
): void {
    trackEvent(
        {
            action,
            category: INTERACTION_TYPE_ITEM_FAVORITING,
            label: heartLabels[location],
            isInteractiveEvent: true,
            additional: {
                ...(isSponsoredItem !== null ? { dimension141: isSponsoredItem } : {}),
                ...(itemId ? { itemId } : {}),
                dimension142: isNewListing,
            },
            eventName: EVENT_WISHLIST,
            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name,
            trigger: heartLabels[location],
        },
        null
    );
}

type TrackHeartEventActionProps = {
    location: FavoritesLocation;
    itemPk?: string | null;
    isSponsored?: boolean | null;
    isNewListing?: boolean;
};

export const trackHeartFavorited = ({
    location,
    itemPk,
    isSponsored,
    isNewListing,
}: TrackHeartEventActionProps): void =>
    trackHeartEvent(
        location,
        'item added',
        itemPk,
        STEP_INTERACTION_ITEM_ADDED,
        isSponsored,
        isNewListing
    );

export const trackHeartUnfavorited = ({
    location,
    itemPk,
    isSponsored,
    isNewListing,
}: TrackHeartEventActionProps): void =>
    trackHeartEvent(
        location,
        'item removed',
        itemPk,
        STEP_INTERACTION_ITEM_REMOVED,
        isSponsored,
        isNewListing
    );

function trackFolderEvent(
    step_interaction_name: StepInteractionNameValues,
    location: FavoritesLocation,
    noteIncluded?: boolean,
    itemPk?: string
): void {
    const trigger = hasKey(folderLabels, location) ? folderLabels[location] : 'pdp';
    const hasNoteIncluded = noteIncluded ? 1 : 0;
    trackEvent(
        {
            action: step_interaction_name,
            label: trigger,
            category: INTERACTION_TYPE_ITEM_FAVORITING,
            value: hasNoteIncluded,
            isInteractiveEvent: true,
            additional: itemPk ? { itemPk } : {},
            eventName: EVENT_WISHLIST,
            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name,
            trigger,
            step_type: hasNoteIncluded,
        },
        null
    );
}

const trackFolderClick = (location: FavoritesLocation): void =>
    trackFolderEvent(STEP_INTERACTION_ITEM_FOLDER_CLICKED, location);
const trackItemRemoved = (location: FavoritesLocation): void =>
    trackFolderEvent(STEP_INTERACTION_ITEM_REMOVED_FROM_FOLDER, location);
const trackItemAdded = (
    location: FavoritesLocation,
    noteIncluded: boolean,
    itemPk: string
): void => {
    const trigger = heartLabels[location];
    const hasNoteIncluded = noteIncluded ? 1 : 0;
    trackEvent(
        {
            action: 'item added into folder',
            label: trigger,
            category: INTERACTION_TYPE_ITEM_FAVORITING,
            value: hasNoteIncluded,
            isInteractiveEvent: true,
            additional: itemPk ? { itemPk } : {},
            eventName: EVENT_WISHLIST,
            interaction_type: INTERACTION_TYPE_ITEM_FAVORITING,
            step_interaction_name: STEP_INTERACTION_ITEM_ADDED_TO_FOLDER,
            trigger,
            step_type: hasNoteIncluded,
        },
        null
    );
};
const trackFolderCreated = (location: FavoritesLocation, noteIncluded: boolean): void =>
    trackFolderEvent(STEP_INTERACTION_ITEM_FOLDER_CREATED, location, noteIncluded);

export const folderTrackingHooks = {
    trackFolderClick,
    trackItemRemoved,
    trackItemAdded,
    trackFolderCreated,
};

/**
 * @generated SignedSource<<2594b6dc4bbacc1feb52075e2eea24ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FavoritesProvider_item$data = ReadonlyArray<{
  readonly ecommerceTrackingParams: any | null;
  readonly serviceId: string | null;
  readonly " $fragmentType": "FavoritesProvider_item";
}>;
export type FavoritesProvider_item$key = ReadonlyArray<{
  readonly " $data"?: FavoritesProvider_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FavoritesProvider_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ecommerceTrackingParams",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "356cb0c92b48bccb424e2571f17c0e35";

export default node;

import { localStorage } from 'dibs-browser-storage';

const HAS_SEEN_FAVORITES_TOOLTIP = 'HAS_SEEN_FAVORITES_TOOLTIP';

export function hasSeenFavoritesTooltip(): boolean {
    return !!localStorage.getItem(HAS_SEEN_FAVORITES_TOOLTIP) || false;
}

export function logFavoritesTooltipView(): void {
    localStorage.setItem(HAS_SEEN_FAVORITES_TOOLTIP, true);
}

export const FORCE_FAVORITE_ITEM_ID = 'FORCE_FAVORITE_ITEM_ID' as const;
export const FORCE_FOLDER_ITEM_ID = 'FORCE_FOLDER_ITEM_ID' as const;

export function getForceFavoriteItemId(key: string): JsonType {
    return localStorage.getItem(key);
}

export function setForceFavoriteItemId(key: string, itemId: string): void {
    localStorage.setItem(key, itemId);
}

export function clearForceFavoriteItemId(key: string): void {
    localStorage.deleteItem(key);
}

// 1 day
const AUCTION_PROMPT_EXPIRATION_TIME = 24 * 60 * 60 * 1000;

const getExpirationTimestamp = (): number => new Date().getTime() + AUCTION_PROMPT_EXPIRATION_TIME;

const AUCTION_SAVE_PROMPT_DISMISSED = 'AUCTION_SAVE_PROMPT_DISMISSED';

export const setAuctionPromptDismissedTimestamp = (): void => {
    localStorage.setItem(AUCTION_SAVE_PROMPT_DISMISSED, getExpirationTimestamp());
};
